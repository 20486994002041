<template>
    <div class="vue-template auth" >
        <form @submit.prevent="userLogin">
            <h3>Sign In</h3>
            <div class="d-grid gap-3">
              <div class="form-group">
                  <label>Email address</label>
                  <input type="email" class="form-control form-control-lg" v-model="user.email" />
              </div>

              <div class="form-group">
                  <label>Password</label>
                  <input type="password" class="form-control form-control-lg" v-model="user.password" />
              </div>
              <div class="d-grid gap-2">
                <button type="submit" class="btn btn-dark btn-lg">Sign In</button>
              </div>
              <p class="forgot-password text-right">
                  <router-link to="/forgot-password">Forgot password ?</router-link>
              </p>
            </div>
        </form>
    </div>
</template>


<script>
import { auth } from '@/services/firebase/index.js';

export default {
  data() {
    return {
      user: {   
        email: '',
        password: ''
      }
    };
  },
  methods: {
    userLogin() {
        auth.signInWithEmailAndPassword(this.user.email, this.user.password)
        .then((result) => {
            if (result.user.emailVerified !== true) {
              var currentUser = auth.currentUser;
              this.sendVerificationMail(currentUser);
              window.alert('Please validate your email address. Kindly check your inbox.');
            } else {
              this.$store.dispatch('setUpApplication', result.user.uid);
            }
        })
        .catch((error) => {
          alert(error.message);
        });
    },
    sendVerificationMail(currentUser) {
        return currentUser.sendEmailVerification()
        .then(() => {
            this.$router.push('/');
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  }
};
</script>